import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import slider_01 from "../../../Resources/Images/slider/slider_01.webp";
import slider_02 from "../../../Resources/Images/slider/slider_02.webp";
import slider_03 from "../../../Resources/Images/slider/slider_03.webp";
import slider_04 from "../../../Resources/Images/slider/slider_04.webp";
import UIButton from "../../Atoms/Buttons/UIButton";
import ModalIngredients from "../../Molecules/Modals/ModalIngredients";
import logotype from "../../../Resources/Images/Logo/pizzaza-logo-logotipo.png";


const SliderHome = ({action,}) => {
    const slidesData = [
        {
            lead: "Pizzaza de",
            title: "Carnes",
            title__modal: "Pizzaza de Carnes",
            subtitle: "Pollo, Carne de res, tocineta, salchichón",
            ingredients: "Pollo, Carne de res, tocineta, salchichón",
            ingredients__modal: "Pollo, Carne de res, tocineta, salchichón",
            imageSrc: slider_01,
        },
        {
            lead: "Pizzaza de",
            title: "Pollo-Champiñón",
            title__modal: "Pizzaza de Pollo-Champiñón",
            subtitle: "Pollo, champiñones, cebolla grilled",
            ingredients: "Pollo, champiñones, cebolla grilled",
            ingredients__modal: "Pollo, champiñones, cebolla grilled",
            imageSrc: slider_02,
        },
        {
            lead: "Pizzaza",
            title: "Criolla",
            title__modal: "Pizzaza Criolla",
            subtitle: "Maíz, salchichón, Piña, cebolla grilled, pimentón grilled",
            ingredients: "Maíz, salchichón, Piña, cebolla grilled, pimentón grilled",
            ingredients__modal: "Maíz, salchichón, Piña, cebolla grilled, pimentón grilled",
            imageSrc: slider_03,
        },
        {
            lead: "Pizzaza de",
            title: "Vegetales",
            title__modal: "Pizzaza de Vegetales",
            subtitle: "champiñones, Maíz, cebolla grilled, pimentón grilled, raices chinas",
            ingredients: "champiñones, Maíz, cebolla grilled, pimentón grilled, raices chinas",
            ingredients__modal: "champiñones, Maíz, cebolla grilled, pimentón grilled, raices chinas",
            imageSrc: slider_04,
        },
    ];
    return (
        <React.Fragment>
            <Carousel
                autoPlay
                infiniteLoop
                swipeable={true}
                showThumbs={false}
                showStatus={false}
                interval={8000}
            >
                {slidesData.map((slide, index) => (
                    <div key={index} className="relative h-[270px] lg:h-[450px] bg-black bg-cover bg-center" style={{ backgroundImage: `url(${slide.imageSrc})` }}>
                        <div class="absolute  bg-black/60 w-full h-full"></div>
                        <div className="absolute w-full h-full">
                            <div className="flex h-full items-center justify-center">
                                <div className="w-full md:w-1/2 px-5 ">
                                    <img className="!w-[60%] lg:!w-[380px]" alt="logotipo" src={logotype} />
                                    <h1 className="text-white font-black text-2xl lg:text-5xl font-fauna">{slide.title}</h1>
                                    <h2 className="text-white font-semibold text-sm lg:text-xl">
                                        {slide.subtitle}
                                    </h2>
                                    <ModalIngredients
                                        bsPrefix={'button__primary text-sm lg:text-md'}
                                        ingredients__modal={slide.ingredients__modal}
                                        title__modal={slide.title__modal}
                                        text__button={'Pide ahora'}
                                    />
                                </div>
                            </div>
                        </div>
                        {/* <p className="legend">Legend 1</p> */}
                    </div>
                ))}
            </Carousel>
        </React.Fragment>
    );
};
export default SliderHome;
