import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function ModalIngredients({ bsPrefix,text__button,title__modal,ingredients__modal, }) {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <Button bsPrefix={bsPrefix} onClick={handleShow}>
                {text__button}
            </Button>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <p className='font-black text-xl text-yellow-950 mb-0'>
                            {title__modal}
                        </p>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className='font-medium text-md text-yellow-950 mb-0'>
                        {ingredients__modal}
                    </p>
                    <p className='font-light text-xs text-yellow-950 mb-0 mt-2'>
                        Puedes escoger tus ingredientes, incluso agregarle a tu gusto.
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button bsPrefix='button__secondary text-white decoration-transparent font-md' onClick={handleClose}>
                        Cerrar
                    </Button>
                    <a
                        target="_blank"
                        className="button__primary text-yellow-950 decoration-transparent font-md"
                        href="https://wa.me/573128592751"
                    >
                        Pide ahora
                    </a>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ModalIngredients;