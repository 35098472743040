import React from "react";
import { Col, Image, Row } from "react-bootstrap";
import logo from "../../../Resources/Images/Logo/pizzaza_logo_v.webp";

const Footer = () => {
    return (
        <React.Fragment>
            <Row className="w-100 px-3 py-5 m-0 bg-[#280000]" id="contacto">
                <Col className="col-12 col-lg-3 text-center">
                    <img className="w-40 m-auto" src={logo} />
                    <p className="text-white">
                        Escríbenos:
                    </p>
                    <a href="mailto: pizzazacol@gmail.com" className="text-white font-bold text-lg decoration-transparent">
                        pizzazacol@gmail.com
                    </a>
                    <p className="text-white">
                        +57 312 8592751
                    </p>
                    <p className="text-white">
                        Bogotá, Colombia.
                    </p>
                </Col>
                <Col className="col-12 col-lg-9">
                    <p className="text-white text-center font-bold text-lg">
                        Encuéntranos:
                    </p>
                    <iframe 
                        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15904.886260559248!2d-74.0433201!3d4.7315357!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e3f855ab2a13fbb%3A0xdc83dfa1bff6c6fb!2sPizzaza!5e0!3m2!1ses!2sco!4v1688491263135!5m2!1ses!2sco" 
                        width="800" height="400" 
                        allowfullscreen="" 
                        loading="lazy" 
                        referrerpolicy="no-referrer-when-downgrade"
                        className="m-auto w-full"
                    >
                    </iframe>
                </Col>
            </Row>
            <Row className="w-100 px-3 py-2 m-0 bg-black">
                <Col className="text-end">
                    <p className="text-white text-[10px]">
                        Sitio web desarrollado por {' '} 
                        <a href="https://enlacewebdesign.com/" target="_blank" className="decoration-transparent text-white font-bold">
                            Enlace Web Design
                        </a>
                    </p>
                </Col>
            </Row>
        </React.Fragment>
    );
};
export default Footer;