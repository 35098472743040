import React from "react";
import PlayGround from "../../Templates/PlayGround";
import SliderHome from "../../Organisms/Sliders/SliderHome";
import SliderProducts from "../../Organisms/Sliders/SliderProducts";
import CardProduct from "../../Molecules/Cards/CardProduct";
import image__product from "../../../Resources/Images/Products/image_product_000.webp";
import CardCoupon from "../../Molecules/Cards/CardCoupon";
import FormContact from "../../Molecules/Forms/FormContact";
import { Button, Col, Row } from "react-bootstrap";
import image_01 from "../../../Resources/Images/Products/image_card_01.webp";
import image_02 from "../../../Resources/Images/Products/image_card_02.webp";
import image_03 from "../../../Resources/Images/Products/image_card_03.webp";
import image_04 from "../../../Resources/Images/Products/image_card_04.webp";
import UIButton from "../../Atoms/Buttons/UIButton";
import FormOffers from "../../Molecules/Forms/FormOffers";
import iconPizzaza from "../../../Resources/Images/Logo/pizzaza-logo-icon.png";

const HomeOut = () => {

    const ingredientsData = [
        {      
          title__modal: "Pizzaza de Carnes",      
          ingredients: "Pollo, Carne de res, tocineta, salchichón",
          ingredients__modal: "Pollo, Carne de res, tocineta, salchichón",
          imageSrc: image_01,
        },
        {      
          title__modal: "Pizzaza de Pollo-Champiñón",      
          ingredients: "Pollo, champiñones, cebolla grilled",
          ingredients__modal: "Pollo, champiñones, cebolla grilled",
          imageSrc: image_02,
        },
        {      
          title__modal: "Pizzaza Criolla",      
          ingredients: "Maíz, salchichón, Piña, cebolla grilled, pimentón grilled",
          ingredients__modal: "Maíz, salchichón, Piña, cebolla grilled, pimentón grilled",
          imageSrc: image_03,
        },
        { 
          title__modal: "Pizzaza de Vegetales",      
          ingredients: "champiñones, Maíz, cebolla grilled, pimentón grilled, raices chinas",
          ingredients__modal: "champiñones, Maíz, cebolla grilled, pimentón grilled, raices chinas",
          imageSrc: image_04,
        },
    ];
    
    return (
        <React.Fragment>
            <PlayGround>
                <SliderHome />
                <div className="flex flex-col md:flex-row w-full ">
                    <div className="lg:w-3/4 bg-orange-200 pb-4 px-0 lg:px-3" id="menu">
                        <Row className="w-100 p-0 mx-0 justify-content-center my-2">
                            <Col className="col-12 text-center mb-2">
                                <Row className="w-100 px-0 py-6 m-0 flex-col lg:flex-row items-center justify-center">
                                    <Col className="col-4">
                                        <img alt="icon pizzaza" src={iconPizzaza}></img>
                                    </Col>
                                    <Col className="col-6 flex flex-col align-center justify-center">
                                        <p className="font-fauna text-lg lg:text-[22px] text-amber-950">
                                            Pide Tu <strong>Pizzaza</strong> personal con borde de queso:
                                        </p>
                                        <p className="font-fauna text-[30px] lg:text-[60px] font-black text-amber-700">
                                            $10.000 cop
                                        </p>
                                        <a href="https://wa.me/573128592751" target="_blank" className="font-fauna text-lg lg:text-[22px] text-amber-950 font-bold no-underline hover:text-xl transition-all">
                                            +57 312 859 27 51
                                        </a>
                                    </Col>
                                </Row>
                            </Col>
                            <Col className="col-8 col-lg-10 text-center">
                                <p className="font-fauna text-lg text-[28px] text-amber-950 mb-10">
                                    Domicilios en Bogotá, escoge tu sabor:
                                </p>
                            </Col>
                        </Row>
                        <div className="grid grid-cols-2 lg:grid-cols-4 lg:gap-5 py-2 px-8">                            
                            {ingredientsData.map((data, index) => (
                                <CardProduct
                                    key={index}
                                    image__product={data.imageSrc}
                                    nombre__combo={data.title__modal}
                                    ingredients={data.ingredients}
                                    ingredients__modal={data.ingredients__modal}
                                    title__modal={data.title__modal}
                                    modalIngredients={data.ingredients}
                                />
                            ))}
                        </div>
                    </div>                    
                    <div className="lg:w-1/4 bg-orange-900 p-2 lg:p-20">
                        <Row className="w-100 p-0 m-0">
                            <Col className="col-12">
                                <p className="text-md md:text-lg font-bold text-white text-center lg:text-left">
                                    Comunícate con nosotros
                                </p>
                                <p className="text-base font-normal text-white text-center lg:text-left">
                                    Cuéntanos tu experiencia para que podamos mejorar
                                </p>
                                <FormContact />
                                <hr className="w-4/5 mx-auto my-4 border-solid border-amber-400" />                                
                            </Col>
                            <Col className="col-12">
                                {/* <FormOffers /> */}
                                <div className="w-full d-none">
                                    <div className="w-full p-2 text-center">
                                        <p className="text-xs md:text-lg font-bold text-white ">
                                            Oferta especial:
                                        </p>
                                    </div>
                                    <div className="p-2">
                                        <CardCoupon
                                            image__product={image__product}
                                            coupon__name={'Papas y Gaseosa'}
                                            coupon__button__text={'Añadir a mi compra'}
                                            coupon__price={'$5.000'}
                                        />
                                    </div>
                                    <div className="px-5 hidden md:block">
                                        <hr className="w-4/5 mx-auto my-4 border-solid border-amber-400" />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
                <a
                    target="_blank"
                    className="button__whatsapp font-semibold"
                    href="https://wa.me/573128592751"
                >
                    
                </a>
            </PlayGround>
        </React.Fragment >
    );
};
export default HomeOut;