import React from "react";
import Navigation from "../Organisms/Header/Navigation";
import Footer from "../Organisms/Footer/Footer";



const PlayGround = ({children,}) => {
    return (
        <React.Fragment>
            <Navigation />
            <div className="w-screen bg-slate-50">
                {children}
            </div>
            <Footer />
        </React.Fragment>
    );  
};
export default PlayGround;