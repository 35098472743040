import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { FiMenu, FiX } from "react-icons/fi";
import { FiShoppingCart } from 'react-icons/fi';
import logo__pizza from '../../../Resources/Images/Logo/logo512.png' ;
import logo__pizzaza from '../../../Resources/Images/Logo/pizzaza-logo-h.png';
import { Col, Row } from "react-bootstrap";

const Navigation = () => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);

  function handleMenuToggle() {
    setIsOpen(!isOpen);
  }

  useEffect(() => {
    function handleClickOutside(event) {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuRef]);

  return (
    <React.Fragment>
      <nav className=" z-10 flex w-screen bg-slate-900 lg:bg-[#0f172acf] justify-between items-center py-2 px-4 lg:fixed">
        <div>
          <Link to="/">
          <Row className="justify-start">
            <Col>
              <img src={logo__pizzaza} alt="Logo" className="w-auto h-[60px]" />            
            </Col>
          </Row>
          </Link>
        </div>
        <div className="md:flex hidden content-center">
          <a 
            className="text-white hover:text-gray-900 mx-4 py-2 decoration-transparent"             
            href="/#contacto">
            Contacto
          </a>
          <a 
            className="text-white hover:text-gray-900 mx-4 py-2 decoration-transparent"             
            href="/#menu">
            Menú
          </a>
          <a
            target="_blank"
            className="button__primary--whatsapp font-semibold"
            href="https://wa.me/573128592751"
          >
            PIDE AHORA
          </a>
          {/* <Link className="button__primary p-2 mx-2 rounded-full">
            <FiShoppingCart className="w-7 h-5" />
          </Link> */}
        </div>
        <div className="md:hidden" ref={menuRef}>
          <button
            className="p-2 rounded-md focus:outline-none"
            onClick={handleMenuToggle}
          >
            {isOpen ? (
              <FiX className="w-6 h-6" style={{ color: "white" }} />
            ) : (
              <FiMenu className="w-6 h-6" style={{ color: "white" }} />
            )}
          </button>
          {isOpen && (
            <div className="absolute top-[80px] left-0 w-full bg-white shadow-lg z-10 pb-4 text-center">
              <a
                className="block decoration-transparent font-bold px-4 py-2 text-gray-800 hover:bg-gray-100"
                href="/#contacto">
                Contacto
              </a>
              <a
                className="block decoration-transparent font-bold px-4 py-2 text-gray-800 hover:bg-gray-100"
                href="/#menu">
                Menú
              </a>
              <a
                target="_blank"
                className="button__primary--whatsapp font-semibold"
                href="https://wa.me/573128592751"
              >
                PIDE AHORA
              </a>
            </div>
          )}
        </div>
      </nav>
    </React.Fragment>
  );
};

export default Navigation;
