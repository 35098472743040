import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import { Form, Row, Col, Alert } from 'react-bootstrap';

const FormSection = () => {
  return (
    <>
      <Col className="col-12">
        <Form.Group controlId="user_email">
          <Form.Label className='text-white'>Tu correo electrónico:</Form.Label>
          <Form.Control
            type="email"
            name="user_email"
            placeholder="Ingresa tu correo electrónico"
            required
          />
          <Form.Control.Feedback type="invalid">
            Por favor, ingresa un correo electrónico válido.
          </Form.Control.Feedback>
        </Form.Group>
      </Col>
      <Col className="col-12">
        <Form.Group controlId="message">
          <Form.Label className='text-white'>Tu comentario:</Form.Label>
          <Form.Control
            as="textarea"
            name="message"
            placeholder="Escribe tu comentario"
            required
          />
          <Form.Control.Feedback type="invalid">
            Por favor, ingresa tu comentario.
          </Form.Control.Feedback>
        </Form.Group>
      </Col>
      <Col className="col-12 my-2">
        <input className="button__primary w-full" type="submit" value="Enviar tu comentario" />
      </Col>
    </>
  );
};

const FormContact = () => {
  const [Step1, setStep1] = useState(true);
  const [Step2, setStep2] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isFormError, setIsFormError] = useState(false);
  const [isFormValidated, setIsFormValidated] = useState(false);
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    const formElement = form.current;

    if (formElement.checkValidity()) {
      emailjs
        .sendForm('service_ocxdfmp', 'template_rwp7bke', formElement, '_bLo403ZQ11uD7V-6')
        .then(
          (result) => {
            console.log(result.text);
            setIsFormSubmitted(true);
            setIsFormError(false);
            setStep1(false);
            setStep2(true);
          },
          (error) => {
            console.log(error.text);
            setIsFormSubmitted(false);
            setIsFormError(true);
          }
        );
    } else {
      setIsFormValidated(true);
    }
  };

  return (
    <Form ref={form} onSubmit={sendEmail} validated={isFormValidated}>
      <Row className="w-100 p-0 m-0">
        {Step1 && (
          <>
            <FormSection />
            {isFormSubmitted && (
              <Col>
                <Alert variant="success">
                  ¡El formulario ha sido enviado con éxito!
                </Alert>
              </Col>
            )}
            {isFormError && (
              <Col>
                <Alert variant="danger">¡Hubo un error al enviar el formulario!</Alert>
              </Col>
            )}
            {isFormValidated && (
              <Col>
                <Alert variant="danger">
                  Por favor, completa todos los campos requeridos correctamente.
                </Alert>
              </Col>
            )}
          </>
        )}
        {Step2 && (
          <Col>
            <p className='text-center text-white font-thin'>
                ¡El formulario ha sido enviado con éxito, responderemos pronto!
            </p>
          </Col>
        )}
      </Row>
    </Form>
  );
};

export default FormContact;
