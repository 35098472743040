import React from "react";
import ModalIngredients from "../Modals/ModalIngredients";

const CardProduct = ({image__product,nombre__combo,ingredients,title__modal,ingredients__modal, }) => {
  
  return (
    <React.Fragment>
      <div className="bg-white rounded-none lg:rounded-lg w-full  p-2 mt-12 lg:mt-0">
        <img className="px-4 mx-auto -mt-12" src={image__product} alt="Product" />
        <div className="p-2 text-center">
          <div className="flex-col md:flex-row">
            <div>
              <p className="font-bold font-fauna min-h-[50px]">{nombre__combo}</p>
              <ModalIngredients
                bsPrefix={'button__primary text-sm lg:text-md'}
                ingredients__modal={ingredients__modal}
                title__modal={title__modal}
                text__button={'Pide ahora'}
              />
            </div>
            <div className="p-2 md:pl-5">
              <p className="text-sm font-semibold">Ingredientes:</p>
              <p className="text-xs">{ingredients}</p>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CardProduct;
