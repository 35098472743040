import React from "react";



const CardCoupon = ({image__product,coupon__name,coupon__price,coupon__button__text,}) => {
    return (
        <React.Fragment>
            <div className="flex bg-white rounded-lg w-full h-auto md:h-full">
                <div className="w-full md:w-1/3 overflow-hidden">
                    <img className="w-18 h-18 md:w-auto md:h-full" src={image__product}></img>
                </div>
                <div className="w-full md:w-2/3 p-2 md:pl-2 md:pt-2 text-center md:text-left">
                    <div className="flex-col">
                        <div className="w-full">
                            <p className="text-xs md:text-lg font-semibold">
                                {coupon__name}
                            </p>
                            <p className="text-xs md:text-md">
                                {coupon__price}
                            </p>
                        </div>
                        <div className="w-full">
                            <button className="button__primary p-1 text-xs mt-2">
                                {coupon__button__text}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );  
};
export default CardCoupon;