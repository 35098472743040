import React, {lazy, Suspense} from 'react';
import { createBrowserRouter } from 'react-router-dom';
import HomeOut from '../Components/Pages/Home/HomeOut';
import Navigation from '../Components/Organisms/Header/Navigation';

const PageHomeIn = lazy(()=>import('../Components/Pages/HomeIn/HomeIn'));

export const Routes = createBrowserRouter([
    {
        path:'/',
        element: <HomeOut/>
    },
    {
        path:'/inicio',
        element: <Suspense fallback={<div>...</div>}><PageHomeIn/></Suspense>
    },
]);
